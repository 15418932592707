<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :model="formData"
        :rules="searchRules"
        inline
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            Search
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="right">
        <el-button type="text">
          <i
            title="下载"
            class="fa fa-cloud-download icondown"
          />
        </el-button>
      </div>
    </BHeader>
    <div
      v-loading="listInfo.loading"
      class="overview"
    >
      <div class="left">
        <div class="items">
          <div class="num">
            {{ listInfo.sumRevenue || '-' }}
          </div>
          <div class="name">
            Sum(All Channel - Daily Cost) USD
          </div>
        </div>
      </div>
      <div class="right">
        <el-button
          type="primary"
          plain
          @click="handleCreateNewData"
        >
          新建数据
        </el-button>
      </div>
    </div>

    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      style="width: 100%"
      border
    >
      <el-table-column
        label="Date"
        prop="elapsedTime"
        min-width="120px"
      />
      <el-table-column
        label="Channel"
        width="140px"
      >
        <template>
          <span>All Channel</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Daily Cost(USD)"
        prop="dailyCost"
        min-width="120px"
      />
      <el-table-column
        label="Monthly Cost(USD)"
        prop="monthlyCost"
        min-width="120px"
      />
      <el-table-column
        label="操作"
        width="140px"
      >
        <template #default="{row}">
          <BTextButton
            icon="fa fa-trash-o"
            title="删除"
            @click="handleDeleteCategoryItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <Pagination
      v-show="!listInfo.loading"
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
    <!-- 新建 | 修改 数据 -->
    <el-dialog
      :title="dialogInfo.title"
      :visible.sync="dialogInfo.show"
      width="600px"
      @close="handleDialogClose"
    >
      <el-form
        ref="subForm"
        v-loading="dialogInfo.loading"
        :model="dialogInfo.formData"
        :rules="dialogInfo.formRules"
        label-width="200px"
      >
        <el-form-item
          label="Date："
          prop="date"
        >
          <el-date-picker
            v-model="dialogInfo.formData.elapsedTime"
            type="date"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item
          label="Daily Cost："
          prop="dailyCost"
        >
          <el-input
            v-model="dialogInfo.formData.dailyCost"
            clearable
            style="width: 220px"
          >
            <template #append>
              USD
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="Monthly Cost："
          prop="monthlyCost"
        >
          <el-input
            v-model="dialogInfo.formData.monthlyCost"
            clearable
            style="width: 220px"
          >
            <template #append>
              USD
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogInfo.show = false">取 消</el-button>
        <el-button @click="handleDialogSubmit">保 存</el-button>
        <el-button
          :loading="dialogInfo.updateLoading"
          type="primary"
          @click="handleDialogSubmitAndContiue"
        >保存并新建</el-button>
      </span>
    </el-dialog>
  </Layout>
</template>
<script>
import { format } from 'fecha'
import {
  getUserNationStatistics
} from '@/api/electronicEntity.js'
import {
  getOperationalCostListt,
  addOperationalCost,
  deleteCategoryItem
} from '@/api/finance.js'
import {
  cloneDeep
} from 'lodash'

const getToday = () => {
  const targetDay = new Date(Date.now()).getTime()
  return [format(targetDay, 'YYYY-MM-DD'), format(targetDay, 'YYYY-MM-DD')]
}

const formKey = 'AGENCY_COST_ OPERATION'
export default {
  data () {
    const numberCheck = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('此项必须为数字'))
      } else {
        callback()
      }
    }

    const RANGE_CHECK = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error('此项需不小于0'))
      } else {
        callback()
      }
    }

    const DECIMAL_CHECK = (rule, value, callback) => {
      const decimalLength = (value.toString().split('.')[1] || '').length || 0
      if (decimalLength > 2) {
        callback(new Error('小数位数不能多于2位'))
      } else {
        callback()
      }
    }
    return {
      // 平台1:android2:Ios.3:AllChannel
      channelMap: {
        1: 'Android',
        2: 'iOS',
        3: 'All Channel'
      },
      nationList: [],
      filterNationList: [],
      formData: {
        dateRange: getToday(),
        pageNum: 1,
        pageSize: 20
      },
      pickerOptions: {
        disabledDate (time) {
          const dateTime = time.getTime()
          return dateTime < new Date('2020-12-31').getTime() || dateTime > new Date(Date.now()).getTime()
        }
      },
      searchRules: {
        dateRange: [
          { required: true, message: 'Date range is required' }
        ]
      },
      listInfo: {
        list: [],
        total: 0,
        sumRevenue: 0,
        sumCost: 0,
        sumRoi: 0,
        loading: true
      },
      dialogInfo: {
        title: '新建数据',
        show: false,
        loading: false,
        updateLoading: false,
        formData: {},
        formRules: {
          elapsedTime: [
            { required: true, message: 'Date is required' }
          ],
          dailyCost: [
            { required: true, message: 'dailyCost is required' },
            { validator: numberCheck },
            { validator: RANGE_CHECK },
            { validator: DECIMAL_CHECK }
          ],
          monthlyCost: [
            { required: true, message: 'monthlyCost is required' },
            { validator: numberCheck },
            { validator: RANGE_CHECK },
            { validator: DECIMAL_CHECK }
          ]
        }
      }
    }
  },
  mounted () {
    // Promise.all([
    //   this.queryNationList()
    // ]).then(() => {
    //   const storageFormData = sessionStorage.getItem(formKey)
    //   if (storageFormData) {
    //     this.listInfo.total = 9999
    //     this.formData = JSON.parse(storageFormData)
    //   }
    //   this.queryDataList()
    // })
    //   .catch(() => {
    //     this.listInfo.loading = false
    //   })
  },
  created () {
    this.queryNationList()
      .then(() => {
        const storageFormData = sessionStorage.getItem(formKey)
        if (storageFormData) {
          this.listInfo.total = 9999
          this.formData = JSON.parse(storageFormData)
        }
        this.queryDataList()
      })
  },
  methods: {
    handleDeleteCategoryItem (row) {
      this.$confirm('此操作将删除模块, 是否继续', '提示', { type: 'warning ' })
        .then(() => {
          deleteCategoryItem({
            id: row.id
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.queryDataList()
            }
          }).finally(() => {
          })
        })
    },
    numFormatter (row, column, cellValue, index) {
      return !isNaN(cellValue) ? cellValue : '-'
    },

    filterMethod (keyword) {
      this.filterNationList = keyword ? this.nationList.filter(item => {
        return item.nation.toLowerCase().startsWith(keyword.toLowerCase())
      }) : this.nationList
    },
    handleCreateGame () {
      this.newGameForm.sourceId = ''
      this.newGameVisible = true
    },
    doEditData () {
      return new Promise(resolve => {
        setTimeout(() => {
          this.dialogInfo.updateLoading = false
          this.dialogInfo.show = false
          this.queryDataList()
          resolve()
        }, 2000 * Math.random())
      })
    },
    formatQuery (formData) {
      const form = cloneDeep(formData)
      const [startTime, endTime] = form.dateRange
      delete form.dateRange
      return {
        ...form,
        startTime,
        endTime
      }
    },
    changeSelectState (listKey, fullList, scope) {
      scope[listKey].length === fullList.length
        ? scope[listKey] = []
        : scope[listKey] = fullList
    },
    selectButtonLabel (currentList, fullList) {
      return currentList.length === fullList.length ? '取消全选' : '全选'
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    handleDialogSubmitAndContiue () {
      this.handleDialogSubmit()
        .then(() => {
          this.handleCreateNewData()
        })
    },
    doCreateData () {
      return new Promise(resolve => {
        addOperationalCost(this.dialogInfo.formData)
          .then(res => {
            if (res.code === 200) {
              this.$message.success('新建数据成功')
              this.dialogInfo.show = false
              this.queryDataList()
              resolve()
            }
          })
          .finally(() => {
            this.dialogInfo.updateLoading = false
          })
      })
    },
    handleDialogSubmit () {
      return new Promise(resolve => {
        const isEdit = this.dialogInfo.title === '编辑数据'
        this.dialogInfo.updateLoading = true
        this.$refs.subForm.validate(valid => {
          if (valid) {
            if (isEdit) {
              this.doEditData().then(() => {
                resolve()
              })
            } else {
              this.doCreateData().then(() => {
                resolve()
              })
            }
          } else {
            this.dialogInfo.updateLoading = false
          }
        })
      })
    },
    // 新建与修改数据相关方法
    handleCreateNewData () {
      this.dialogInfo.title = '新建数据'
      this.dialogInfo.show = true
    },
    queryDataList () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.listInfo.loading = true
          const query = this.formatQuery(this.formData)
          sessionStorage.setItem(formKey, JSON.stringify(this.formData))
          getOperationalCostListt(query)
            .then(res => {
              if (res.code === 200) {
                this.listInfo.list = res.data.list || []
                this.listInfo.total = res.data.total || 0
                this.listInfo.sumRevenue = res.data.sumRevenue
                this.listInfo.sumCost = res.data.sumCost
                this.listInfo.sumRoi = res.data.sumRoi
              }
            })
            .finally(() => {
              this.listInfo.loading = false
            })
        }
      })
    },
    handleDialogClose () {
      this.dialogInfo.formData = {}
      this.$refs.subForm.resetFields()
    },
    queryNationList () {
      return getUserNationStatistics({
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          const sortNationList = (res.data.list || []).sort((a, b) => {
            if (a.nation < b.nation) {
              return -1
            }
            if (a.nation > b.nation) {
              return 1
            }
            return 0
          })
          this.nationList = sortNationList
          this.filterNationList = sortNationList
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.overview {
  display: flex;
  justify-content: space-between;
  // padding: 0 0 20px 0;
  min-width: 880px;
  margin-bottom: 10px;
  .left {
    display: flex;
    .items {
      // width: 210px;
      padding: 0 30px;
      height: 100px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 26px;
        margin-bottom: 10px;
        .unit {
          font-size: 14px;
        }
      }
      .name {
        font-size: 12px;
      }
    }
  }
}
.pre-header {
  height: 46px;
  .select-all {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
    font-size: 14px;
    margin: 10px 0;
    padding: 10px 20px;
    cursor: pointer;
    background: #f5f7fa;
    box-sizing: border-box;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
    &::before {
      content: "";
      display: block;
      height: 10px;
      background: white;
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
    }
    &:hover {
      color: cornflowerblue;
    }
  }
}
.icondown {
  font-size: 35px;
  margin-right: 50px;
}
</style>
